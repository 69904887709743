export const trainersData = [
	{
		name: 'Константин Селянин',
		description:
			'Руководитель. Ведущий тренер.',
		icon: './images/trainers/selyanin.png',
	},
	{
		name: 'Ольга Сероглазова-Селянина',
		description: 'Коммерческий директор. Ведущий тренер.',
		icon: './images/trainers/seroglazova.png',
	},
  {
		name: 'Вероника Антуфьева',
		description: 'Волшебница. Руководитель команды организаторов.',
		icon: './images/trainers/antufeva.png',
	}
];