export const featuresData = [
	{
		name: '4000+ учеников',
		description:
			'Более 4000 учеников прошли обучение у нас.',
		nameEn: '4000+ students',
		descriptionEn:
				'Over 4,000 people have received training with us.',
		icon: './images/achievements/4000students.jpg',
		imgClass: 'one',
	},
	{
		name: '9+ лет',
		description: 'Более 9 лет на рынке.',
		nameEn: '9+ years',
		descriptionEn: 'Over 9 years in the market.',
		icon: './images/achievements/9years.jpg',
		imgClass: 'two',
	},
	{
		name: '10+ программ',
		description: 'Разработали более 10 авторских программ.',
		nameEn: '10+ programs',
		descriptionEn: 'Developed over 10 proprietary programs.',
		icon: './images/achievements/10programs.jpg',
		imgClass: 'three',
	},
	{
		name: '20+ стран',
		description: 'Клиенты более чем из 20 стран.',
		nameEn: '20+ countrees',
		descriptionEn: 'Clients from over 20 countries.',
		icon: './images/achievements/20countries.jpg',
		imgClass: 'four',
	},
	{
		name: '300+ корпоративных клиентов',
		description: 'Более 300 корпоративных клиентов.',
		nameEn: '300+ corporate clients',
		descriptionEn: 'Over 300 corporate clients.',
		icon: './images/achievements/300clients.jpg',
		imgClass: 'five',
	},
	{
		name: '80+ турниров',
		description: 'Проведено более 80 турниров по переговорам.',
		nameEn: '80+ tournaments',
		descriptionEn: 'Conducted over 80 negotiation tournaments.',
		icon: './images/achievements/80tournaments.jpg',
		imgClass: 'six',
	},
];
