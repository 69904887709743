import React from 'react';
import { Container } from '../../globalStyles';
import {
	FeatureText,
	FeatureSection,
	FeatureTitle,
	FeatureWrapper,
	FeatureColumn,
	FeatureName,
	FeatureTextWrapper,
	AchievementIcon
} from './FeaturesStyles';

import { featuresData } from '../../data/FeaturesData';

const Features = () => {
	const lang = 'Ru';
	return (
    <FeatureSection id="about">
      <Container>
        <FeatureTextWrapper>
          {lang === "Ru" && (
            <>
              <FeatureTitle>О нас</FeatureTitle>
            </>
          )}
          {lang === "En" && (
            <>
              <FeatureTitle>About Us</FeatureTitle>
            </>
          )}
        </FeatureTextWrapper>
        <FeatureWrapper>
          {featuresData.map((el, index) => (
            <FeatureColumn key={index}>
              <AchievementIcon src={el.icon} alt={el.name} />
              <FeatureName>{el.name}</FeatureName>
              <FeatureText>{el.description}</FeatureText>
            </FeatureColumn>
          ))}
        </FeatureWrapper>
      </Container>
    </FeatureSection>
  );
};

export default Features;
