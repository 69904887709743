export const courseOne = {
	id: 'projects',
	topLine: {
		text: 'Курс по подготовке к переговорам.',
		textEn: 'Course on Negotiation Preparation.',
	},
	headline: 'Подготовка к переговорам. Стратагемное мышление.',
	headlineEn: 'Negotiation Preparation. Strategic Thinking.',
	description: 'Базовый цикл. 10 еженедельных вечерних занятий онлайн-курса "Подготовка к переговорам. Стратагемное мышление", развивающего навык профессиональной подготовки к переговорам и стратегическое видение в сложных переговорных и управленческих ситуациях.',
	descriptionEn: 'The Basic Cycle consists of 10 weekly evening sessions of the online course "Negotiation Preparation. Strategic Thinking." which enhances the skill of professional negotiation preparation and strategic vision in complex negotiation and managerial situations.',
	buttonLabel: 'Связаться с нами',
	buttonLabelEn: 'Contact Us',
	linkTo: 'mailto:ou.seroglazova@gmail.com',
	imgStart: 'start',
	img: './images/content/preparation.jpeg',
	backgroundColor: 'linear-gradient(150deg,#c99fff -20%,#4a4eff)',
	start: 'true',
	price: '$500',
	dateStart: '16.01.2024'
};

export const courseTwo = {
	reverse: true,
	topLine: {
		text: 'Курс по ведению сложных переговоров.',
		textEn: 'Course on Handling Tough Negotiations.',
	},
	headline: 'Айкидо сложных переговоров.',
	headlineEn: 'Aikido of Difficult Negotiations.',
	description:
		"Курс базового цикла, посвященный восприятию в переговорах и специальным техникам противодействия жёстким переговорщикам, тренировке навыка трансформации сложной коммуникации в конструктивный диалог. Курс состоит из 10 вечерних онлайн сессий.",
	descriptionEn:
		"The Basic Cycle course focuses on perception in negotiations and specialized techniques to counter tough negotiators, training the skill of transforming complex communication into constructive dialogue online. It consists of 10 evening sessions.",	
	buttonLabel: 'Связаться с нами',
	buttonLabelEn: 'Contact Us',
	linkTo: 'mailto:ou.seroglazova@gmail.com',
	imgStart: 'start',
	img: './images/content/aikido.jpeg',
	backgroundColor: 'linear-gradient(140deg	,#ffaf73 30%,#fffecc 120%)',
	start: 'true',
	price: '$500',
	dateStart: '09.04.2024'
};

export const courseThree = {
	topLine: {
		text: 'Курс по управлению влиянием в переговорах.',
		textEn: 'Course on Influence Management in Negotiations.',
	},
	headline: "Управление влиянием в переговорах.",
	headlineEn: "Influence Management in Negotiations.",
	description:
		'Курс базового цикла, раскрывающий механизмы принятия решений и обязательств, завоевания доверия и достижения согласия, а также противостояния манипуляторам и "профессионалам уступчивости", использующим когнитивные стереотипы и социальные феномены. 10 еженедельных онлайн занятий',
	descriptionEn:
		'The Basic Cycle course reveals the mechanisms of decision-making and commitment, gaining trust and reaching agreements, as well as countering manipulators who employ cognitive stereotypes and social phenomena. It consists of 10 weekly online sessions!',
		buttonLabel: 'Связаться с нами',
		buttonLabelEn: 'Contact Us',
		linkTo: 'mailto:ou.seroglazova@gmail.com',
	imgStart: '',
	backgroundColor: 'linear-gradient(104deg, rgba(151,147,218,1) 0%, rgba(183,137,205,1) 100%)',
	img: './images/content/influence.jpeg',
	start: 'true',
	price: '$600',
	dateStart: '17.09.2024'
};

export const courseFour = {
	reverse: true,
	topLine: {
		text: 'Курс по командным переговорам.',
		textEn: 'Course on Team Negotiations.',
	},
	headline: 'Командные переговоры.',
	headlineEn: 'Team Negotiations.',
	description: 'Курс базового цикла для тех, кто хочет научиться гармонично и эффективно формировать переговорные команды и выстраивать командное взаимодействие в переговорах, разрабатывать и реализовывать сложные переговорные стратегии в команде для достижения более высоких результатов. Курс состоит из 8 еженедельных вечерних онлайн занятий.',
	descriptionEn: 'The Basic Cycle course is designed for those who want to learn how to harmoniously and effectively form negotiation teams and build team collaboration in negotiations, develop and implement complex negotiation strategies within a team to achieve better results. It consists of 8 weekly sessions.',
	buttonLabel: 'Связаться с нами',
	buttonLabelEn: 'Contact Us',
	linkTo: 'mailto:ou.seroglazova@gmail.com',
	imgStart: '',
	backgroundColor: 'linear-gradient(140deg	,#ffaf73 30%,#fffecc 120%)',
	img: './images/content/team.jpeg',
	start: 'true',
	price: '$400',
	dateStart: '09.07.2024'
};

export const courseFive = {
	reverse: false,
	topLine: {
		text: 'Курс практика сложных переговоров.',
		textEn: '',
	},
	headline: 'Практика сложных переговоров.',
	headlineEn: 'Difficult negotiation practice.',
	description: '30 субботних тренингов по управлению конфликтом. Только переговорная практика и анализ конфликтных ситуаций. Подготовка к реальным переговорам. Тренировка игроков к соревнованиям по управленческой борьбе.',
	descriptionEn: '',
	buttonLabel: 'Связаться с нами',
	buttonLabelEn: 'Contact Us',
	linkTo: 'mailto:ou.seroglazova@gmail.com',
	imgStart: 'start',
	backgroundColor: 'linear-gradient(225deg,#0abac2,#b2de94)',
	img: './images/content/difficult-practice.jpg',
	start: 'true',
	price: '$1500',
	dateStart: '27.01.2024'
};

export const courseSix = {
	reverse: true,
	topLine: {
		text: 'Курс принципы и приемы переговоров.',
		textEn: '',
	},
	headline: 'Принципы и приемы переговоров.',
	headlineEn: 'Negotiation prunciples and techniques.',
	description: 'Курс "Универсальной Школы Переговоров" для профессиональных переговорщиков ОНЛАЙН. Практический тренинг переговорных техник. Каждое занятие посвящено отработке одного переговорного принципа или приёма. 40 еженедельных вечерних занятий по четвергам с 14:00 до 17:00 (UTC).',
	descriptionEn: '',
	buttonLabel: 'Связаться с нами',
	buttonLabelEn: 'Contact Us',
	linkTo: 'mailto:ou.seroglazova@gmail.com',
	imgStart: 'start',
	backgroundColor: 'linear-gradient(225deg,#0abac2,#b2de94)',
	img: './images/content/principles-and-techniques.jpg',
	start: 'true',
	price: '$1600',
	dateStart: '18.01.2024'
};

export const courseSeven = {
	reverse: false,
	topLine: {
		text: 'Курс управленческие решения в типовых конфликтах.',
		textEn: '',
	},
	headline: 'Управленческие решения в типовых конфликтах.',
	headlineEn: 'Solution to typical conflicts.',
	description: 'Практический тренинг поиска решений и применения переговорных техник для урегулирования разных типов конфликтов для профессиональных переговорщиков и руководителей из 4 сессий: "Продажи vs Закупки", "Внутрифирменные конфликты", "Личное дело", "Партнёрство". 40 еженедельных занятий по средам, 120 астрономических часов.',
	descriptionEn: '',
	buttonLabel: 'Связаться с нами',
	buttonLabelEn: 'Contact Us',
	linkTo: 'mailto:ou.seroglazova@gmail.com',
	imgStart: 'start',
	backgroundColor: 'linear-gradient(225deg,#0abac2,#b2de94)',
	img: './images/content/solutions-to-typical-conflicts.png',
	start: 'true',
	price: '$1600',
	dateStart: '17.01.2024'
};