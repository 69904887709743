export const navbarData = [
	{
		to: 'about',
		text: 'О нас',
		textEn: 'About',
	},
	{
		to: 'courses',
		text: 'Курсы',
		textEn: 'Courses',
	},
	{
		to: 'trainers',
		text: 'Команда',
		textEn: 'Trainers',
	},
	{
		to: 'clients',
		text: 'Клиенты',
		textEn: 'Clients',
	},
];
