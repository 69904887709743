import React from 'react';
import { FaFacebook, FaYoutube, FaTelegram } from 'react-icons/fa';
import {
	FooterContainer,
	FooterSubscription,
	FooterSubHeading,
	SocialMedia,
	SocialMediaWrap,
	SocialLogo,
	SocialIcon,
	WebsiteRights,
	SocialIcons,
	SocialIconLink,
	FooterImage,
} from './FooterStyles';

const Footer = () => {
	return (
		<FooterContainer>
			<FooterImage className="footerPattern" src="./images/bgFooter.svg" />
			<FooterSubscription>
				<SocialLogo to="/">
					<SocialIcon src="./images/logo.png" />
					Universal School of Negotiation
				</SocialLogo>
				<FooterSubHeading>Join Us</FooterSubHeading>
			</FooterSubscription>

			<SocialMedia>
				<SocialMediaWrap>
					<WebsiteRights>© Copyright 2023, USN. All Rights Reserved</WebsiteRights>
					<SocialIcons>
						<SocialIconLink href="https://www.facebook.com/usnexpert" target="blank" aria-label="Facebook">
							<FaFacebook />
						</SocialIconLink>
						<SocialIconLink href="https://www.youtube.com/channel/UCkwnW2xPS-WDijGC66Jtk_g" target="blank" aria-label="Youtube">
							<FaYoutube />
						</SocialIconLink>
						<SocialIconLink href="https://t.me/usnexpert" target="blank" aria-label="Telegram">
							<FaTelegram />
						</SocialIconLink>
					</SocialIcons>
				</SocialMediaWrap>
			</SocialMedia>
		</FooterContainer>
	);
};

export default Footer;
