import React from 'react';
import { Container } from '../../globalStyles';
import {
	ClientSection,
	ClientColumn,
	ClientText,
	ClientTitle,
	ClientRow,
	ClientWrapper,
	ClientImage,
	ClientTextWrapper,
} from './ClientsStyles';
import { clientsData } from '../../data/ClientsData';

const Clients = () => {
	const lang = 'Ru';

	return (
    <ClientSection id="clients">
      <Container>
        <ClientTextWrapper>
          {lang === "Ru" && (
            <>
              <ClientTitle>Наши клиенты</ClientTitle>
              <ClientText>
                Мы работаем с клиентами по всему миру.
              </ClientText>
            </>
          )}
          {lang === "En" && (
            <>
              <ClientTitle>Our Clients</ClientTitle>
              <ClientText>
                We’ve been working with the teams around the world.
              </ClientText>
            </>
          )}
        </ClientTextWrapper>

        <ClientRow>
          {clientsData.map((clients, clientsIndex) => (
            <ClientColumn key={clientsIndex}>
              {clients.map((el, index) => (
                <ClientWrapper key={index}>
                  <ClientImage src={`./images/companies/${el.name}.jpg`} />
                </ClientWrapper>
              ))}
            </ClientColumn>
          ))}
        </ClientRow>
      </Container>
    </ClientSection>
  );
};

export default Clients;
