import React from 'react';
import Clients from '../components/Clients/Clients';
import Content from '../components/Content/Content';
import Features from '../components/Features/Features';
import Trainers from '../components/Trainers/Trainers';
import Header from '../components/Header/Header';
import { courseOne, courseTwo, courseThree, courseFour, courseFive, courseSix, courseSeven } from '../data/CoursesData';
import { Heading } from '../globalStyles';


const HomePage = () => {
	const lang = 'Ru';

	return (
		<>
			<Header />
			<Features />
			{lang === 'Ru' && <Heading id="courses">Наши курсы</Heading>}
			{lang === 'En' && <Heading id="courses">Our Courses</Heading>}
			<Content {...courseOne} />
			<Content {...courseTwo} />
			<Content {...courseThree} />
			<Content {...courseFour} />
			<Content {...courseFive} />
			<Content {...courseSix} />
			<Content {...courseSeven} />
			<Trainers />
			<Clients />
		</>
	);
};

export default HomePage;
