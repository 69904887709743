import React, { useEffect, useState } from 'react';
import { FiMail } from 'react-icons/fi';
import {
	HeaderSection,
	Heading,
	HeaderText,
	ButtonContainer,
	HeaderButton,
	HeaderImage,
	HeaderContent,
	ButtonWrapper
} from './HeaderStyles';
import { useInView } from 'react-intersection-observer';
import Modal from '../Modal/Modal';

const Header = () => {
	const [showModal, setShowModal] = useState(false);
	const lang = 'Ru';

	const toggleModal = () => {
		if (!showModal) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}

		setShowModal(!showModal);
	};

	const { ref, inView } = useInView({
		rootMargin: '-100px',
	});

	useEffect(() => {
		//console.log(inView);
	}, [inView]);

	return (
    <>
      <HeaderSection id="header">
        <HeaderImage className="pattern" src="./images/bgHeader.svg" />
        <HeaderContent>
          {lang === "Ru" && (
            <>
              <Heading>Мы учим искусству переговоров</Heading>
              <HeaderText>Достигай целей сохраняя отношения</HeaderText>
            </>
          )}
          {lang === "En" && (
            <>
              <Heading>We teach the art of negotiation</Heading>
              <HeaderText>
                Achieve your goals and preserving relationships
              </HeaderText>
            </>
          )}
          <ButtonContainer ref={ref}>
            <ButtonWrapper>
              <HeaderButton
                onClick={toggleModal}
                className={inView ? "" : "corner"}
              >
                {lang === "Ru" &&
                  (inView ? (
                    <> Связаться с нами</>
                  ) : (
                    <FiMail color="white" size="2.3rem" />
                  ))}
                {lang === "En" &&
                  (inView ? (
                    <> Chat with us</>
                  ) : (
                    <FiMail color="white" size="2.3rem" />
                  ))}
              </HeaderButton>
            </ButtonWrapper>
          </ButtonContainer>
        </HeaderContent>
      </HeaderSection>
      <Modal showModal={showModal} toggleModal={toggleModal} />
    </>
  );
};

export default Header;
