import React from "react";
import { Container } from "../../globalStyles";
import {
  TrainersText,
  TrainersSection,
  TrainersTitle,
  TrainersWrapper,
  TrainersColumn,
  TrainersName,
  TrainersTextWrapper,
  TrainersIcon,
} from "./TrainersStyles";

import { trainersData } from "../../data/TrainersData";

const Trainers = () => {
  const lang = 'Ru';

  return (
    <TrainersSection id="trainers">
      <Container>
        <TrainersTextWrapper>
          {lang === 'Ru' && <TrainersTitle>Наша команда</TrainersTitle>}
          {lang === 'En' && <TrainersTitle>Trainers</TrainersTitle>}
        </TrainersTextWrapper>
        <TrainersWrapper>
          {trainersData.map((el, index) => (
            <TrainersColumn key={index}>
              <TrainersIcon src={el.icon} alt={el.name} />
              <TrainersName>{el.name}</TrainersName>
              <TrainersText>{el.description}</TrainersText>
            </TrainersColumn>
          ))}
        </TrainersWrapper>
      </Container>
    </TrainersSection>
  );
};

export default Trainers;
