import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { HiMail } from 'react-icons/hi';

import {
	FormColumn,
	FormWrapper,
	//FormInput,
	FormSection,
	FormRow,
	//FormTitle,
	FormLabel,
	FormInputRow,
	FormButton,
	//FormMessage,
	SocialIconLink
} from './FormStyles';
import { Container } from '../../globalStyles';
// import validateForm from './validateForm';
// import contactUsByEmail from '../../email/email-service';

const Form = ({toggleModal}) => {

	const lang = 'Ru';
	// const [name, setName] = useState('');
	// const [email, setEmail] = useState('');
	// const [subject, setSubject] = useState('');
	// const [message, setMessage] = useState('');
	// const [error, setError] = useState(null);
	// const [success, setSuccess] = useState(null);

	// let successMessage = 'Message was sent!'
	// if (lang === 'Ru') {
	// 	successMessage = 'Сообщение отправлено!';
	// } else {
	// 	successMessage = 'Message was sent!';	
	// }

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();
	// 	const resultError = validateForm({ name, email, subject, message });

	// 	if (resultError !== null) {
	// 		setError(resultError);
	// 		return;
	// 	}

	// 	await contactUsByEmail(name, email, subject, message);

	// 	setName('');
	// 	setEmail('');
	// 	setSubject('');
	// 	setMessage('');
	// 	setError(null);
	// 	setSuccess(successMessage);
	// };

	// const messageVariants = {
	// 	hidden: { y: 30, opacity: 0 },
	// 	animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
	// };

	// const FormLabels = [];
	// if (lang === 'Ru') {
	// 	FormLabels.push('Имя');
	// 	FormLabels.push('Почта');
	// 	FormLabels.push('Заголовок');
	// 	FormLabels.push('Текст сообщения');
	// } else {
	// 	FormLabels.push('Name');
	// 	FormLabels.push('Email');
	// 	FormLabels.push('Subject');
	// 	FormLabels.push('Message');
	// }
	// const formData = [
	// 	{ label: FormLabels[0], value: name, onChange: (e) => setName(e.target.value), type: 'text' },
	// 	{ label: FormLabels[1], value: email, onChange: (e) => setEmail(e.target.value), type: 'email' },
	// 	{
	// 		label: FormLabels[2],
	// 		value: subject,
	// 		onChange: (e) => setSubject(e.target.value),
	// 	},
	// 	{
	// 		label: FormLabels[3],
	// 		value: message,
	// 		onChange: (e) => setMessage(e.target.value),
	// 	},
	// ];

	return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn>
					{/* onSubmit={handleSubmit} */}
            <FormWrapper onClick={toggleModal} > 
              {/* {formData.map((el, index) => (
								<FormInputRow key={index}>
									<FormLabel>{el.label} *</FormLabel>
									<FormInput
										type={el.type}
										value={el.value}
										onChange={el.onChange}
									/>
								</FormInputRow>
							))} */}
              <FormInputRow>
                <HiMail />
								<SocialIconLink
                  href="mailto:ou.seroglazova@gmail.com"
                  target="blank"
                  aria-label="Telegram"
                >
                <FormLabel>ou.seroglazova@gmail.com</FormLabel>
								</SocialIconLink>
              </FormInputRow>
              <FormInputRow>
                <FaTelegramPlane />
                <SocialIconLink
                  href="https://t.me/Olga_Seroglazova_Selyanina"
                  target="blank"
                  aria-label="Telegram"
                >
                  <FormLabel>@Olga_Seroglazova_Selyanina</FormLabel>
                </SocialIconLink>
              </FormInputRow>
              {lang === "Ru" && <FormButton type="submit">ok</FormButton>}
              {lang === "En" && <FormButton type="submit">ok</FormButton>}
            </FormWrapper>
            {/* {error && (
							<FormMessage
								variants={messageVariants}
								initial="hidden"
								animate="animate"
								error
							>
								{error}
							</FormMessage>
						)}
						{success && (
							<FormMessage
								variants={messageVariants}
								initial="hidden"
								animate="animate"
							>
								{success}
							</FormMessage>
						)} */}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Form;
